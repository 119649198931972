import React from "react";
import _ from "lodash";
import Card from "../Card";

export default ({
  sizeOptions = [],
  onChange = _.noop,
  value,
  title = "Size",
  sideNote = "Required",
  brandColor = "red",
  T,
  appStyles,
  currencySymbol = "$",
  firstComponentWithError,
  kioskMode,
  animateErrorElement,
  refEl = _.noop,
}) => (
  <Card
    refEl={refEl}
    appStyles={appStyles}
    animateError={firstComponentWithError && animateErrorElement}
    kioskMode={kioskMode}
    hasErrors={firstComponentWithError}
  >
    <Card.Header>
      <Card.Header.Title appStyles={appStyles}>{T(title)}</Card.Header.Title>
      <Card.Header.SideNote appStyles={appStyles} color={brandColor}>
        <strong>{sideNote}</strong>
      </Card.Header.SideNote>
    </Card.Header>
    <Card.Content>
      <Card.Content.RadioGroup
        name="size"
        value={value}
        onChange={onChange}
        appStyles={appStyles}
        wrap
      >
        {_.map(sizeOptions, (sizeOption) => (
          <Card.Content.RadioOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={brandColor}
            value={sizeOption.id}
            key={sizeOption.menuItemId}
          >
            <span>{sizeOption.itemName}</span>
            <span>
              {sizeOption.price ? `${currencySymbol} ${sizeOption.price.toFixed(appStyles.pricesFixedPoint)}`:""}
            </span>
          </Card.Content.RadioOption>
        ))}
      </Card.Content.RadioGroup>
    </Card.Content>
  </Card>
);
